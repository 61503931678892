/* Default styles for small screens */

html{
  height: 100%;
  width: 100%;

}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: 20%;
}
body {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom, #111111, #333333);
  background-attachment: fixed;
}


.square-container {
  display: grid;
  width: 100px;
  height: 150px;
  border-radius: 60px;
  padding: 30px;
  gap: 20px;
  list-style: none;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(2, 1fr);
  background: rgba(255, 255, 255, 0.2);
  margin-left: 1%;
  max-height:fit-content;
  overflow-y: auto;
}

.clicked {
  background: rgba(170, 24, 35, 0.8);
}

.disc {
  background: white;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
}

.disc-clicked {
  background: rgba(170, 24, 35, 0.8);
}


button {
  text-decoration: none;
  display: inline-block;
  margin-left: 50px;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  font-size: 16px;
  font-weight: 900;
  border-radius: 50px;
  background-color: #4caf50;
  width: 150px; /* set a fixed width for the button */
  height: 200px;
}

.dice-container {
  display: flex;
  flex-direction: row;
}

.scoreboard {
  margin: 0 auto;
  display: flex;
  flex-direction: column; /* Change to column for smaller screens */
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}


.yellow-button {
  background-color: #b4a835;
}

.current-player {
  color: red;
}
