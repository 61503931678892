/* Default styles */
/* Default styles for scoreboard */


.scoreboard-body-row-cell.disabled {
  background-color: #cccccc;
  color: #666666;
}

.scoreboard-table {
  font-size: 0.8rem;
  max-width: 80%; /* Adjust the maximum width as needed */
  width: auto; /* Allow the table to size itself based on content */
  height: 80%; /* You can adjust this as needed */
}

.scoreboard-table th,
.scoreboard-table td {
  padding: 5%;
  text-align: center;
  border: 1% solid #dddddd;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden;    /* Hide any overflow */
  
}

.scoreboard-table th {
  background-color: #f2f2f2;
}

.scoreboard-table td {
  border-radius: 10%;
}

.scoreboard-cell {
  background-color: #f2f2f2;
}

.scoreboard-cell-empty {
  background-color: #f2f2f2;
  color: blue;
}

.scoreboard-cell-above-zero {
  cursor: pointer;
  background-color: aquamarine;
}
